.FormContainer {
  background-color: white;

  @media (max-width: 840px) {
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(1, 58, 76, 0.13);
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 24px;
    padding-bottom: 10px;
    margin: auto 16px;
    width: auto;
  }
}

.Section {
  font-weight: 600;
}

.Divider {
  padding-top: 1rem;
}

.StepDetails {
  font-weight: 500;
  font-size: 11px;
  margin: auto 0;

  @media (max-width: 840px) {
    margin: auto 0 2px;
  }
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 40px;
  flex: 1 1;
  padding-top: 14px;

  @media (max-width: 840px) {
    padding: 4px 0 14px;
  }

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
  }

  .ButtonPrevious {
    margin-right: 16px;
    padding: 0 14px;
  }
}

.SettingsActionButtons {
  @media (max-width: 340px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;

    .ButtonPrevious {
      margin: 20px 16px 0;
    }
  }
}

.TitleContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
}

.TitleHeader {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 21px;
  font-weight: 500;
}

.MobileCreateAccountButton {
  display: flex;
  justify-content: center;
}

.HideStep {
  display: none;
}
