@import '~styles/base.scss';

.SubmitButton {
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 42px;
  width: 100%;
}

.FormContent {
  & > p {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.4;
  }
}
