@import '~styles/base.scss';

.FooterLinks {
  display: flex;
  justify-content: space-between;

  a {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 2rem;
    color: $of-forest-green-01;

    .Icon {
      margin-right: 3px;
    }
  }
}

.CaptchaField {
  margin-bottom: 32px;

  div iframe {
    @media screen and (min-width: 490px) {
      transform: scale(1.21);
      -webkit-transform: scale(1.21);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }
}
