.MfaCode > :first-child {
  margin-top: 1rem;
}

.Resend {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem !important;

  .ResendButton {
    margin: 0 0.5rem;
  }
}
