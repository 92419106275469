.Header {
  margin-bottom: 1rem;
  text-align: center;
  word-break: break-all;
  word-break: break-word;

  h2 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
