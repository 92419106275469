@import '~styles/base.scss';

.IconList {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 20px;

  .Icon {
    width: 100px;
    height: 80px;
    text-align: center;
    margin-top: 10px;
    padding: 10px;

    .Name {
      margin-top: 6px;
      word-wrap: break-word;
      max-width: 100px;
      display: block;
      font-size: 11px;
    }
  }
}

.Total {
  padding: 10px 36px;
  font-size: 14px;
  font-weight: 600;
}
