@import '~styles/base.scss';

.RecoverLinkContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  font-weight: 600;

  & > * {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

.ButtonsContainer {
  margin: 30px 0 16px;

  & > :first-child {
    margin-bottom: 8px;
  }

  .Button {
    font-size: 1rem;
    font-weight: bold;
    padding: 12px 42px;
    width: 100%;
  }
}
