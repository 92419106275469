@import '~styles/base.scss';

.Wrapper {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;

  .Background {
    width: 100%;
    min-height: 100vh;
    background-color: $of-plum-05;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex: none;
  }

  .BackgroundPink1 {
    background-color: $of-plum-05;
    background-image: url('./oneflow_public_pink_1.jpg');

    @include respondTo(lg, max) {
      background-image: url('./oneflow_public_small_pink.png');
      background-position: top;
      background-size: contain;
    }
  }

  .BackgroundPink2 {
    background-color: $of-plum-05;
    background-image: url('./oneflow_public_pink_2.jpg');

    @include respondTo(lg, max) {
      background-image: url('./oneflow_public_small_pink.png');
      background-position: top;
      background-size: contain;
    }
  }

  .BackgroundPink3 {
    background-color: $of-plum-05;
    background-image: url('./oneflow_public_pink_3.jpg');

    @include respondTo(lg, max) {
      background-image: url('./oneflow_public_small_pink.png');
      background-position: top;
      background-size: contain;
    }
  }

  .BackgroundBlue1 {
    background-color: $of-dark-green-01;
    background-image: url('./oneflow_public_blue_1.jpg');

    @include respondTo(lg, max) {
      background-image: url('./oneflow_public_small_blue.jpg');
      background-position: top;
      background-size: contain;
    }
  }

  .BackgroundBlue2 {
    background-color: $of-dark-green-01;
    background-image: url('./oneflow_public_blue_2.jpg');

    @include respondTo(lg, max) {
      background-image: url('./oneflow_public_small_blue.jpg');
      background-position: top;
      background-size: contain;
    }
  }

  .BackgroundBlue3 {
    background-color: $of-dark-green-01;
    background-image: url('./oneflow_public_blue_3.jpg');

    @include respondTo(lg, max) {
      background-image: url('./oneflow_public_small_blue.jpg');
      background-position: top;
      background-size: contain;
    }
  }

  .RightColumn {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 50%;
    min-height: 100vh;
    margin-left: -50%;
    padding: 30px 0;
    flex: none;
    align-items: center;
    justify-content: center;

    @include respondTo(lg, max) {
      width: 100%;
      margin-left: -100%;
    }
  }

  .Container {
    width: 420px;
    margin: 0 auto;
    padding: 0 8px 0;
    border-radius: 20px;
    background-color: white;
    box-shadow: -7px 8px 14px rgba(black, 0.2);

    @include respondTo(lg, max) {
      margin: 0 30px;
    }

    @include respondTo(sm, max) {
      width: auto;
      padding: 0;
    }
  }

  .ContainerSignupFlow {
    width: 537px !important;

    @media (max-width: 840px) {
      max-width: 350px !important;
      background-color: transparent;
      box-shadow: none;
      margin: 0;
      width: inherit !important;
    }
  }

  .Logo {
    text-align: center;
    padding-top: 1.5rem;
  }

  .Content {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 2rem 1.7rem;
    border-radius: 0.25rem;
    color: $of-forest-green-01;

    @include respondTo(sm, max) {
      padding: 2rem 2rem;
    }
  }

  .ContentSignupFlow {
    padding: 36px 0;
  }

  .ContentPersonalId {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding-top: 1rem;
    padding-bottom: 3rem;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    border-radius: 0.25rem;
    color: $of-forest-green-01;

    @include respondTo(sm, max) {
      padding: 2rem 2rem;
    }
  }

  .SignupLink {
    box-sizing: border-box;
    padding: 0 1.7rem 1.5rem;
  }

  .BackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;

    .Logo {
      padding-bottom: 1.5rem;
    }

    .Content {
      padding: 3rem;

      @include respondTo(sm, max) {
        margin: 0 1rem;
        padding: 3rem 2rem;
      }
    }

    .SignupLink {
      color: white;
    }
  }
}

.Centered {
  .Background {
    /* Light */
    background-color: $of-plum-05;
    background-image: url('./oneflow_public_small_pink.png');
    background-position: top;
    background-size: contain;
  }

  .RightColumn {
    width: 100%;
    margin-left: -100%;
  }
}
