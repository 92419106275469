.Content {
  & > label,
  & > div {
    margin: 0;
  }

  & > p {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.4;
  }
}
