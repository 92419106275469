@import '~styles/base.scss';

.Link {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-top: 2rem;
  color: $of-forest-green-01;

  .Icon {
    margin-right: 3px;
  }
}
