.JobDetailsContainer {
  display: flex;
  flex-direction: row;

  label:first-child {
    padding-right: 10px;
  }

  label:last-child {
    padding-left: 10px;
  }

  @media (max-width: 840px) {
    flex-direction: column;

    label:first-child,
    label:last-child {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
