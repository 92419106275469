.Wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.LinkText {
  margin: 1rem 0;
}

.LinkButton {
  display: inline-block;
}
